<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12"></div>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div class="flex flex-wrap mt-4">
          <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
            >
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                      FLUXO DE CAIXA MES : {{ dt_mes }}-{{ aberto }}
                      <div>
                        <button
                          class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalplanejar()"
                        >
                          <fas :icon="['fas', 'magnifying-glass']" />
                          Localizar planejamento do mes !
                        </button>
                        <div
                          v-if="showModalplanejar"
                          class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                        >
                          <div class="relative w-auto my-6 mx-auto max-w-3xl">
                            <!--content-->
                            <div
                              class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            >
                              <!--header-->
                              <div
                                class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                              >
                                <h3 class="text-3xl font-semibold">
                                  Planejamento
                                </h3>
                                <button
                                  class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                  v-on:click="toggleModalplanejar()"
                                >
                                  <span
                                    class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                                  >
                                    ×
                                  </span>
                                </button>
                              </div>
                              <!--body-->
                              <div class="relative p-6 flex-auto">
                                <div class="flex mt-4 mb-8">
                                  <div class="w-full md:w-1/2 px-3">
                                    <TextInput
                                      label="Digitar o ano de referência"
                                      placeholder="Pesquisar..."
                                      v-model:input="searchPlanejar"
                                      inputType="text"
                                    />
                                  </div>
                                  <div class="w-full md:w-1/2 px-3">
                                    <form
                                      @submit.prevent="onSubmitPlanejar"
                                      @click="getIdplanejarList"
                                    >
                                      <LoadingButton
                                        :isLoading="isLoadingPlanejar"
                                        class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                      >
                                        Pesquisar
                                      </LoadingButton>
                                    </form>
                                  </div>
                                  <div class="mx-auto py-4">
                                    <table class="table-auto text-xs">
                                      <thead>
                                        <tr class="text-gray-600 bg-gray-300">
                                          <th class="border px-4 py-2">Id</th>
                                          <th class="border px-4 py-2">
                                            Tarefa
                                          </th>
                                          <th class="border px-4 py-2">Mes</th>
                                          <th class="border px-4 py-2">Ação</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          class="text-gray-500 bg-gray-100"
                                          v-for="listPlanning in listPlanejars"
                                          :key="listPlanning"
                                        >
                                          <td>{{ listPlanning.id }}</td>
                                          <td>{{ listPlanning.tarefa }}</td>
                                          <td
                                            v-if="listPlanning.dt_mes !== null"
                                            class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                                          >
                                            {{
                                              formatDate(listPlanning.dt_mes)
                                            }}
                                          </td>
                                          <td>
                                            <SubmitFormButton
                                              btnText=""
                                              @click="
                                                selectPlanejar(listPlanning)
                                              "
                                              v-on:click="toggleModalplanejar()"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div
                                      class="flex items-center justify-center p-2"
                                    >
                                      <v-pagination
                                        v-model="pageEntradas"
                                        :pages="pageCountEntradas"
                                        :range-size="1"
                                        active-color="#337aff"
                                        @update:modelValue="listEntradas"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <vue-snotify />
                              <!--footer-->
                              <div
                                class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                              >
                                <button
                                  class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  v-on:click="toggleModalplanejar()"
                                >
                                  Voltar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="showModalplanejar"
                          class="opacity-25 fixed inset-0 z-40 bg-black"
                        ></div>
                      </div>

                      <tr>
                        <td>
                          <div>
                            <button
                              class="bg-sky-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              v-on:click="toggleModalentrada()"
                            >
                              Cupom compra
                            </button>
                            <div
                              v-if="showModalentrada"
                              class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                            >
                              <div
                                class="relative w-auto my-6 mx-auto max-w-3xl"
                              >
                                <!--content-->
                                <div
                                  class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                                >
                                  <!--body-->
                                  <div class="relative p-6 flex-auto">
                                    <div
                                      class="border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                    >
                                      <TextInputDate
                                        label=""
                                        placeholder=""
                                        v-model:input="dt_compracupom"
                                        inputType="hidden"
                                        readonly
                                      />
                                      <h6 class="text-3xl font-semibold">
                                        Cupom compra(entrada) Nº
                                        {{ id_solicitacao }}
                                      </h6>
                                      <TextInput
                                        label=""
                                        placeholder=""
                                        v-model:input="id_fornecedor"
                                        inputType="hidden"
                                        readonly
                                      />
                                      <a class="text-sky-400 font-bold m-4"
                                        >Fornecedor : {{ id_fornecedor }}-{{
                                          nome_fornecedor
                                        }}
                                      </a>
                                      <a class="text-sky-400 font-bold"
                                        >Data compra :
                                        {{ dt_compracupom }}
                                      </a>
                                    </div>

                                    <div
                                      class="flex mt-4 mb-8 border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                    >
                                      <div class="w-full md:w-1/2 px-3">
                                        <TextInput
                                          label="Digitar o código de barra"
                                          placeholder="Pesquisar estoque..."
                                          v-model:input="searchStockCB"
                                          inputType="text"
                                        />
                                      </div>
                                      <div class="w-full md:w-1/2 px-3">
                                        <form
                                          @submit.prevent="onSubmitStock"
                                          @click="getStockListProdCB"
                                        >
                                          <LoadingButton
                                            :isLoading="isLoadingStock"
                                            class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                          >
                                            Pesquisar estoque
                                          </LoadingButton>
                                        </form>
                                      </div>
                                      <div class="mx-auto py-4">
                                        <table class="table-auto text-xs">
                                          <thead>
                                            <tr
                                              class="text-gray-600 bg-gray-300"
                                            >
                                              <th class="border px-4 py-2">
                                                Cód barra
                                              </th>
                                              <th class="border px-4 py-2">
                                                Produto
                                              </th>
                                              <th class="border px-4 py-2">
                                                Estoque
                                              </th>
                                              <th class="border px-4 py-2">
                                                Ação
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              class="text-gray-500 bg-gray-100"
                                              v-for="listStock in listStocks"
                                              :key="listStock"
                                            >
                                              <td>
                                                {{ listStock.codigo_barra }}
                                              </td>
                                              <td>
                                                {{ listStock.nome_produto }}
                                              </td>
                                              <td>{{ listStock.estoque }}</td>
                                              <td>
                                                <div
                                                  class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                                >
                                                  <SubmitFormButton
                                                    btnText=""
                                                    @click="
                                                      selectProduct(listStock)
                                                    "
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <div
                                          class="flex items-center justify-center p-2"
                                        >
                                          <v-pagination
                                            v-model="pageStocks"
                                            :pages="pageCountStocks"
                                            :range-size="1"
                                            active-color="#337aff"
                                            @update:modelValue="listStocks"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                      >
                                        <TextInput
                                          label=""
                                          placeholder=""
                                          v-model:input="id_produto"
                                          inputType="hidden"
                                          readonly
                                        />
                                        <a class="text-sky-400 font-bold"
                                          >PRODUTO : {{ id_produto }}-{{
                                            nome_produto
                                          }}</a
                                        >
                                      </div>
                                    </div>
                                    <div
                                      class="grid grid-cols-4 gap-4 w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                    >
                                      <div>
                                        <TextInput
                                          label="Quantidade Caixa"
                                          placeholder="Quantidade Caixa"
                                          v-model:input="cxqtd"
                                          inputType="text"
                                        />
                                      </div>
                                      <div>
                                        <TextInput
                                          label="Qtde unidade"
                                          placeholder="Quantidade unidade"
                                          v-model:input="cxqtdun"
                                          inputType="text"
                                        />
                                      </div>
                                      <div>
                                        <SubmitFormButton
                                          class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                          btnText="Caixa * unidade"
                                          @click="multiplicacxunidade()"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                    >
                                      <div
                                        class="grid grid-cols-4 gap-4 w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                      >
                                        <div class="w-full md:w-auto px-3">
                                          <TextInput
                                            label="Código barra"
                                            placeholder="Código barra"
                                            v-model:input="codigo_barra"
                                            inputType="hidden"
                                          />
                                          {{ codigo_barra }}
                                        </div>
                                        <div class="w-full md:w-auto px-3">
                                          <TextInput
                                            label="Quantidade"
                                            placeholder="Quantidade"
                                            v-model:input="quantidade"
                                            inputType="text"
                                          />
                                        </div>
                                        <div class="w-full md:w-auto px-3">
                                          <TextInput
                                            label="Valor unitário"
                                            placeholder="Valor unitário"
                                            v-model:input="vlrunitario"
                                            inputType="text"
                                          />
                                        </div>
                                        <div class="w-full md:w-auto px-3">
                                          <SubmitFormButton
                                            class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                            btnText="Qtd + Vlr Un"
                                            @click="multiplicarprod()"
                                          />
                                        </div>
                                        <div
                                          class="w-full text-gray-700 md:w-auto px-3"
                                        >
                                          <TextInput
                                            label="Valor total"
                                            placeholder="Vlr total"
                                            v-model:input="vlrtotal_item"
                                            inputType="hidden"
                                            reandonly
                                          />
                                          {{ formatMoedaBR(vlrtotal_item) }}
                                        </div>
                                        <div
                                          class="w-full text-gray-700 md:w-auto px-3"
                                        >
                                          <TextInput
                                            label="Vlr pedido"
                                            placeholder="Valor total pedido"
                                            v-model:input="valorTotalPedido"
                                            inputType="hidden"
                                            reandonly
                                          />
                                          {{ formatMoedaBR(valorTotalPedido) }}
                                        </div>
                                        <div class="w-full md:w-auto px-6">
                                          <form
                                            @submit.prevent="onSubmitCompra"
                                            @click="registerSolicitacaocompra"
                                          >
                                            <LoadingButton
                                              :isLoading="isLoadingItem"
                                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                            >
                                              Salvar/Incluir
                                            </LoadingButton>
                                          </form>
                                        </div>

                                        <div class="w-full md:w-auto px-6">
                                          <form
                                            @submit.prevent="onSubmitCompra"
                                            @click="getSolicitItemListcompra"
                                          >
                                            <LoadingButton
                                              :isLoading="isLoadingCompra"
                                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                            >
                                              Listar
                                            </LoadingButton>
                                          </form>
                                        </div>
                                      </div>
                                      <div
                                        class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                      >
                                        <div class="w-full md:w-full px-6">
                                          <SubmitFormButton
                                            class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                            btnText="CALCULAR FORMA DE PAGAMENTO"
                                            @click="calculaformapgto()"
                                          />
                                          <div class="grid grid-cols-4 gap-4">
                                            <div>
                                              <div
                                                class="w-full text-gray-700 md:w-auto px-3"
                                              >
                                                <TextInput
                                                  label="Vlr a vista"
                                                  placeholder="Vlr a vista"
                                                  v-model:input="vlrTotalavista"
                                                  inputType="text"
                                                  reandonly
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              <div
                                                class="w-full text-gray-700 md:w-auto px-3"
                                              >
                                                <TextInput
                                                  label="Vlr a prazo"
                                                  placeholder="Vlr a prazo"
                                                  v-model:input="vlrTotalaprazo"
                                                  inputType="text"
                                                  reandonly
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              <div
                                                class="w-full text-gray-700 md:w-auto px-3"
                                              >
                                                <TextInput
                                                  label="Vlr débito"
                                                  placeholder="Vlr débito"
                                                  v-model:input="vlrTotaldebito"
                                                  inputType="text"
                                                  reandonly
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              <div
                                                class="w-full text-gray-700 md:w-auto px-3"
                                              >
                                                <TextInput
                                                  label="Vlr crédito"
                                                  placeholder="Vlr crédito"
                                                  v-model:input="
                                                    vlrTotalcredito
                                                  "
                                                  inputType="text"
                                                  reandonly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="flex flex-wrap mt-8 mb-6">
                                            <div
                                              class="h-full flex items-center px-3"
                                            >
                                              <RouterLinkButton
                                                btnText="PDF"
                                                color="sky"
                                                tipo="fas"
                                                icone="print"
                                                url=""
                                                @click="gerarPDF"
                                              />
                                            </div>
                                            <div
                                              class="h-full flex items-center px-3"
                                            >
                                              <RouterLinkButton
                                                btnText="Finalizar"
                                                color="sky"
                                                tipo="fas"
                                                icone="check"
                                                url=""
                                                @click="finalizarCupomcompra"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="bg-green-500 w-full h-1"
                                      ></div>
                                      <div class="text-gray-600 text-xl">
                                        Itens da solicitação
                                      </div>
                                      <div
                                        class="bg-green-500 w-full h-1"
                                      ></div>
                                      <table class="table-auto text-xs">
                                        <thead>
                                          <tr class="text-gray-600 bg-gray-300">
                                            <th class="border px-4 py-2">ID</th>
                                            <th class="border px-4 py-2">
                                              Código barras
                                            </th>
                                            <th class="border px-4 py-2">
                                              Descrição
                                            </th>
                                            <th class="border px-4 py-2">
                                              Quantidade
                                            </th>
                                            <th class="border px-4 py-2">
                                              Vlr unitario
                                            </th>
                                            <th class="border px-4 py-2">
                                              Vlr total
                                            </th>
                                            <th class="border px-4 py-2">
                                              Ação
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-if="listSolicitItemscompra"
                                            class="text-gray-500 bg-gray-100"
                                            v-for="listSolicitItemcompra in listSolicitItemscompra"
                                            :key="listSolicitItemcompra"
                                          >
                                            <td>
                                              {{ listSolicitItemcompra.id }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemcompra.codigo_barra
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemcompra.nome_produto
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemcompra.quantidade
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemcompra.valor_unitario
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemcompra.valor_totalProduto
                                              }}
                                            </td>
                                            <td>
                                              <SubmitFormButton
                                                btnText="Deletar"
                                                class="underline text-red-500 hover:text-red-600"
                                                @click="
                                                  deleteItemSolitacao(
                                                    listSolicitItemcompra.id
                                                  )
                                                "
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div
                                        class="bg-green-500 w-full h-1"
                                      ></div>
                                      <div
                                        class="flex items-center justify-center p-2"
                                      >
                                        <v-pagination
                                          v-model="pageItemscompra"
                                          :pages="pageCountItemscompra"
                                          :range-size="1"
                                          active-color="#337aff"
                                          @update:modelValue="
                                            getSolicitItemListcompra
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <vue-snotify />
                                  <!--footer-->
                                  <div
                                    class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                                  >
                                    <button
                                      class="text-sky-500 bg-transparent border border-solid border-sky-500 hover:bg-sky-500 hover:text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      v-on:click="toggleModalentrada()"
                                    >
                                      Voltar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="showModal"
                              class="opacity-25 fixed inset-0 z-40 bg-black"
                            ></div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button
                              class="bg-sky-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              v-on:click="toggleModalsaida()"
                            >
                              Cupom venda
                            </button>
                            <div
                              v-if="showModalsaida"
                              class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                            >
                              <div
                                class="relative w-auto my-6 mx-auto max-w-3xl"
                              >
                                <!--content-->
                                <div
                                  class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                                >
                                  <!--header
                                  class="shadow-md h-16 w-16 m-4 bg-teal-400 inline-flex"-->
                                  <!-- <div
                                    class="flex p-5 border-b border-solid border-slate-200 rounded-t"
                                  >
                                    <div
                                      class="rounded-t mb-2 px-6 py-3 border-0"
                                    >
                                      <div
                                        class="w-full text-gray-900 md:w-full px-6"
                                      >
                                        <h6 class="text-3xl font-semibold">
                                          Cupom venda(saída) Nº
                                          {{ id_solicitacaovenda }}
                                        </h6>
                                                                              <TextInput
                                        label=""
                                        placeholder=""
                                        v-model:input="id_cliente"
                                        inputType="hidden"
                                        readonly
                                      />

                                        <a class="text-sky-400 font-bold m-4"
                                          >Cliente : {{ id_cliente }}-{{
                                            nome_cliente
                                          }}
                                        </a>
                                                                                <TextInputDate
                                          label=""
                                          placeholder=""
                                          v-model:input="dt_vendacupom"
                                          inputType="hidden"
                                          readonly
                                        />

                                        <a class="text-sky-400 font-bold"
                                          >Data venda :
                                          {{ dt_vendacupom }}
                                        </a>
                                      </div>
                                    </div>
                                    <button
                                      class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                      v-on:click="toggleModalsaida()"
                                    >
                                      <span
                                        class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                                      >
                                        ×
                                      </span>
                                    </button>
                                  </div> -->
                                  <!--body-->
                                  <div class="relative p-6 flex-auto">
                                    <div
                                      class="border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                    >
                                      <h6 class="text-3xl font-semibold">
                                        Cupom venda(saída) Nº
                                        {{ id_solicitacaovenda }}
                                      </h6>
                                      <TextInput
                                        label=""
                                        placeholder=""
                                        v-model:input="id_cliente"
                                        inputType="hidden"
                                        readonly
                                      />
                                      <a class="text-sky-400 font-bold m-4"
                                        >Cliente : {{ id_cliente }}-{{
                                          nome_cliente
                                        }}
                                      </a>
                                      <TextInputDate
                                        label=""
                                        placeholder=""
                                        v-model:input="dt_vendacupom"
                                        inputType="hidden"
                                        readonly
                                      />
                                      <a class="text-sky-400 font-bold"
                                        >Data venda :
                                        {{ dt_vendacupom }}
                                      </a>
                                    </div>
                                    <div
                                      class="flex mt-4 mb-8 border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                    >
                                      <div class="flex mt-4 mb-8">
                                        <div class="w-full md:w-1/2 px-3">
                                          <TextInput
                                            label="Digitar o código de barra"
                                            placeholder="Pesquisar estoque..."
                                            v-model:input="searchStockCB"
                                            inputType="text"
                                          />
                                        </div>
                                        <div class="w-full md:w-1/2 px-3">
                                          <form
                                            @submit.prevent="onSubmitStock"
                                            @click="getStockListProdCB"
                                          >
                                            <LoadingButton
                                              :isLoading="isLoadingStock"
                                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                            >
                                              Pesquisar estoque
                                            </LoadingButton>
                                          </form>
                                        </div>
                                        <div class="mx-auto py-4">
                                          <table class="table-auto text-xs">
                                            <thead>
                                              <tr
                                                class="text-gray-600 bg-gray-300"
                                              >
                                                <th class="border px-4 py-2">
                                                  Cód barra
                                                </th>
                                                <th class="border px-4 py-2">
                                                  Produto
                                                </th>
                                                <th class="border px-4 py-2">
                                                  Estoque
                                                </th>
                                                <th class="border px-4 py-2">
                                                  Ação
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                class="text-gray-500 bg-gray-100"
                                                v-for="listStock in listStocks"
                                                :key="listStock"
                                              >
                                                <td>
                                                  {{ listStock.codigo_barra }}
                                                </td>
                                                <td>
                                                  {{ listStock.nome_produto }}
                                                </td>
                                                <td>{{ listStock.estoque }}</td>
                                                <td>
                                                  <div
                                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                                  >
                                                    <SubmitFormButton
                                                      btnText=""
                                                      @click="
                                                        selectProduct(listStock)
                                                      "
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <div
                                            class="flex items-center justify-center p-2"
                                          >
                                            <v-pagination
                                              v-model="pageStocks"
                                              :pages="pageCountStocks"
                                              :range-size="1"
                                              active-color="#337aff"
                                              @update:modelValue="listStocks"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                      >
                                        <TextInput
                                          label=""
                                          placeholder=""
                                          v-model:input="id_produto"
                                          inputType="hidden"
                                          readonly
                                        />
                                        <a class="text-sky-400 font-bold"
                                          >Produto : {{ id_produto }}-{{
                                            nome_produto
                                          }}</a
                                        >
                                        <div class="w-full md:w-full px-6">
                                          <div
                                            class="grid grid-cols-4 gap-4 w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                          >
                                            <div>
                                              <TextInput
                                                label="Quantidade Caixa"
                                                placeholder="Quantidade Caixa"
                                                v-model:input="cxqtd"
                                                inputType="text"
                                              />
                                            </div>
                                            <div>
                                              <TextInput
                                                label="Qtde unidade"
                                                placeholder="Quantidade unidade"
                                                v-model:input="cxqtdun"
                                                inputType="text"
                                              />
                                            </div>
                                            <div>
                                              <SubmitFormButton
                                                class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                                btnText="Caixa * unidade"
                                                @click="multiplicacxunidade()"
                                              />
                                            </div>
                                          </div>
                                          <div
                                            class="grid grid-cols-4 gap-4 w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                          >
                                            <div>
                                              <TextInput
                                                label="Código barra"
                                                placeholder="Código barra"
                                                v-model:input="codigo_barra"
                                                inputType="hidden"
                                              />
                                              {{ codigo_barra }}
                                            </div>
                                            <div class="w-full md:w-auto px-3">
                                              <TextInput
                                                label="Quantidade"
                                                placeholder="Quantidade"
                                                v-model:input="quantidade"
                                                inputType="text"
                                              />
                                            </div>
                                            <div>
                                              <TextInput
                                                label="Valor unitário"
                                                placeholder="Valor unitário"
                                                v-model:input="vlrunitario"
                                                inputType="text"
                                              />
                                            </div>
                                            <div>
                                              <SubmitFormButton
                                                class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                                btnText="Qtd + Vlr Unitátio"
                                                @click="multiplicarprod()"
                                              />
                                            </div>
                                            <div>
                                              <div
                                                class="w-full text-gray-700 md:w-auto px-3"
                                              >
                                                <TextInput
                                                  label="Valor total"
                                                  placeholder="Vlr total"
                                                  v-model:input="vlrtotal_item"
                                                  inputType="hidden"
                                                  reandonly
                                                />
                                                {{
                                                  formatMoedaBR(vlrtotal_item)
                                                }}
                                              </div>
                                            </div>
                                            <div>
                                              <div
                                                class="w-full text-gray-700 md:w-auto px-3"
                                              >
                                                <TextInput
                                                  label="Vlr pedido"
                                                  placeholder="Valor total pedido"
                                                  v-model:input="
                                                    valorTotalPedido
                                                  "
                                                  inputType="hidden"
                                                  reandonly
                                                />
                                                {{
                                                  formatMoedaBR(
                                                    valorTotalPedido
                                                  )
                                                }}
                                              </div>
                                            </div>
                                            <div
                                              class="w-full text-gray-700 md:w-auto px-3"
                                            >
                                              <form
                                                @submit.prevent="onSubmitVenda"
                                                @click="
                                                  registerSolicitacaovenda
                                                "
                                              >
                                                <LoadingButton
                                                  :isLoading="isLoadingVenda"
                                                  class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                                >
                                                  Salvar/Incluir
                                                </LoadingButton>
                                              </form>
                                            </div>
                                            <div
                                              class="w-full text-gray-700 md:w-auto px-3"
                                            >
                                              <form
                                                @submit.prevent="onSubmitVenda"
                                                @click="getSolicitItemListvenda"
                                              >
                                                <LoadingButton
                                                  :isLoading="isLoadingVenda"
                                                  class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                                >
                                                  Listar
                                                </LoadingButton>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                                  >
                                    <div class="w-full md:w-full px-6">
                                      <SubmitFormButton
                                        class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                        btnText="CALCULAR FORMA DE PAGAMENTO"
                                        @click="calculaformapgto()"
                                      />

                                      <div class="grid grid-cols-4 gap-4">
                                        <div>
                                          <div
                                            class="w-full text-gray-700 md:w-auto px-3"
                                          >
                                            <TextInput
                                              label="Vlr a vista"
                                              placeholder="Vlr a vista"
                                              v-model:input="vlrTotalavista"
                                              inputType="text"
                                              reandonly
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            class="w-full text-gray-700 md:w-auto px-3"
                                          >
                                            <TextInput
                                              label="Vlr a prazo"
                                              placeholder="Vlr a prazo"
                                              v-model:input="vlrTotalaprazo"
                                              inputType="text"
                                              reandonly
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            class="w-full text-gray-700 md:w-auto px-3"
                                          >
                                            <TextInput
                                              label="Vlr débito"
                                              placeholder="Vlr débito"
                                              v-model:input="vlrTotaldebito"
                                              inputType="text"
                                              reandonly
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            class="w-full text-gray-700 md:w-auto px-3"
                                          >
                                            <TextInput
                                              label="Vlr crédito"
                                              placeholder="Vlr crédito"
                                              v-model:input="vlrTotalcredito"
                                              inputType="text"
                                              reandonly
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="flex flex-wrap mt-8 mb-6">
                                        <div
                                          class="h-full flex items-center px-3"
                                        >
                                          <RouterLinkButton
                                            btnText="PDF"
                                            color="sky"
                                            tipo="fas"
                                            icone="print"
                                            url=""
                                            @click="gerarPDF"
                                          />
                                        </div>
                                        <div
                                          class="h-full flex items-center px-3"
                                        >
                                          <RouterLinkButton
                                            btnText="Finalizar"
                                            color="sky"
                                            tipo="fas"
                                            icone="check"
                                            url=""
                                            @click="finalizarCupomvenda"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        class="bg-green-500 w-full h-1"
                                      ></div>
                                      <div class="text-gray-600 text-xl">
                                        Itens da solicitação
                                      </div>
                                      <div
                                        class="bg-green-500 w-full h-1"
                                      ></div>
                                      <table class="table-auto text-xs">
                                        <thead>
                                          <tr class="text-gray-600 bg-gray-300">
                                            <th class="border px-4 py-2">ID</th>
                                            <th class="border px-4 py-2">
                                              Código barras
                                            </th>
                                            <th class="border px-4 py-2">
                                              Descrição
                                            </th>
                                            <th class="border px-4 py-2">
                                              Quantidade
                                            </th>
                                            <th class="border px-4 py-2">
                                              Vlr unitario
                                            </th>
                                            <th class="border px-4 py-2">
                                              Vlr total
                                            </th>
                                            <th class="border px-4 py-2">
                                              Ação
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-if="listSolicitItemsvendas"
                                            class="text-gray-500 bg-gray-100"
                                            v-for="listSolicitItemsvenda in listSolicitItemsvendas"
                                            :key="listSolicitItemsvendas"
                                          >
                                            <td>
                                              {{ listSolicitItemsvenda.id }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemsvenda.codigo_barra
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemsvenda.nome_produto
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemsvenda.quantidade
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemsvenda.valor_unitario
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                listSolicitItemsvenda.valor_totalProduto
                                              }}
                                            </td>
                                            <td>
                                              <SubmitFormButton
                                                btnText="Deletar"
                                                class="underline text-red-500 hover:text-red-600"
                                                @click="
                                                  deleteItemSolitacao(
                                                    listSolicitItem.id
                                                  )
                                                "
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div
                                        class="bg-green-500 w-full h-1"
                                      ></div>
                                      <div
                                        class="flex items-center justify-center p-2"
                                      >
                                        <v-pagination
                                          v-model="pageItemsvenda"
                                          :pages="pageCountItemsvenda"
                                          :range-size="1"
                                          active-color="#337aff"
                                          @update:modelValue="
                                            getSolicitItemListvenda
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <vue-snotify />
                                  <!--footer-->
                                  <div
                                    class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                                  >
                                    <button
                                      class="text-sky-500 bg-transparent border border-solid border-sky-500 hover:bg-sky-500 hover:text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      v-on:click="toggleModalsaida()"
                                    >
                                      Voltar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="showModal"
                              class="opacity-25 fixed inset-0 z-40 bg-black"
                            ></div>
                          </div>
                        </td>
                        <td>
                          <RouterLinkButton
                            btnText="Voltar"
                            color="sky"
                            tipo="fas"
                            icone="arrow-left"
                            url="dashboard"
                          />
                        </td>
                      </tr>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="bg-green-500 w-full h-1"></div>
              <div class="text-gray-900 text-xl">Fluxo Entrada(Compra)</div>
              <div class="block w-full overflow-x-auto">
                <table
                  class="items-center w-full bg-transparent border-collapse"
                >
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Ordem
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Data
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Vlr total
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="listCashflows_b in listCashflows_buy"
                      :key="listCashflows_buy.id"
                    >
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <div>
                          <div class="w-full md:w-auto px-6">
                            <form
                              @submit.prevent="onSubmitCompra"
                              @click="getCompraList(listCashflows_b.dt_compra)"
                              v-on:click="toggleModalListaentrada()"
                            >
                              <LoadingButton
                                :isLoading="isLoadingCompra"
                                class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                              >
                                <fas :icon="['fas', 'pen']" />
                              </LoadingButton>
                            </form>
                          </div>
                          <div
                            v-if="showModalListaentrada"
                            class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                          >
                            <div class="relative w-auto my-6 mx-auto max-w-3xl">
                              <!--content-->
                              <div
                                class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                              >
                                <!--header
                                  class="shadow-md h-16 w-16 m-4 bg-teal-400 inline-flex"-->
                                <div
                                  class="flex p-5 border-b border-solid border-slate-200 rounded-t"
                                ></div>
                                <!--body-->
                                <div class="text-gray-600 text-xl">
                                  Pedido(s) de compra por dia
                                </div>
                                <div class="bg-green-500 w-full h-1"></div>
                                <table class="table-auto text-xs">
                                  <thead>
                                    <tr class="text-gray-600 bg-gray-300">
                                      <th class="border px-4 py-2">Pedido</th>
                                      <th class="border px-4 py-2">
                                        Fornecedor
                                      </th>
                                      <th class="border px-4 py-2">
                                        Data Compra
                                      </th>
                                      <th class="border px-4 py-2">
                                        valorTotalPedido
                                      </th>
                                      <th class="border px-4 py-2">Status</th>
                                      <th class="border px-4 py-2">Ação</th>
                                      <th class="border px-4 py-2"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-if="listSolicitCompras"
                                      class="text-gray-500 bg-gray-100"
                                      v-for="listSolicitCompra in listSolicitCompras"
                                      :key="listSolicitCompra"
                                    >
                                      <td>{{ listSolicitCompra.id }}</td>
                                      <td>
                                        {{
                                          listSolicitCompra.supplier
                                            .nomeFantasia
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          formatDate(
                                            listSolicitCompra.dt_emissao
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          formatMoedaBR(
                                            listSolicitCompra.valorTotalPedido
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{ listSolicitCompra.situacao }}
                                      </td>
                                      <td>
                                        <router-link
                                          :to="
                                            'solicitacaoentradaEproduto/' +
                                            listSolicitCompra.id
                                          "
                                          class="underline text-blue-500 hover:text-blue-600"
                                        >
                                          <fas :icon="['fas', 'pen']" />
                                        </router-link>
                                      </td>
                                      <td
                                        v-if="
                                          listCashflows_b.situacao !==
                                          'FINALIZADO'
                                        "
                                        class="underline text-blue-500 hover:text-blue-600"
                                      >
                                        <RouterLinkButton
                                          btnText=""
                                          color="red"
                                          tipo="fas"
                                          icone="trash"
                                          url=""
                                          @click="
                                            cancelaEpedidocompra(
                                              listSolicitCompra.id
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="bg-green-500 w-full h-1"></div>
                                <div class="text-gray-600 text-xl">
                                  Resumo dos Pedido(s) por dia
                                </div>
                                <div class="grid grid-cols-3 gap-4">
                                  <div class="text-gray-600 text-xl">
                                    Total Venda :
                                    {{ formatMoedaBR(this.valorTotalCompras) }}
                                  </div>
                                  <div class="text-gray-600 text-xl">
                                    Total a vísta :
                                    {{ formatMoedaBR(this.valorTotalVista) }}
                                  </div>
                                  <div class="text-gray-600 text-xl">
                                    Total a prazo :
                                    {{ formatMoedaBR(this.valorTotalPrazo) }}
                                  </div>
                                  <div class="text-gray-600 text-xl">
                                    Total a débito :
                                    {{ formatMoedaBR(this.valorTotalDebito) }}
                                  </div>
                                  <div class="text-gray-600 text-xl">
                                    Total a crédito :
                                    {{ formatMoedaBR(this.valorTotalCredito) }}
                                  </div>
                                </div>
                                <vue-snotify />

                                <div
                                  class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                                >
                                  <div
                                    class="h-full flex items-center px-3"
                                  ></div>
                                  <button
                                    class="text-sky-500 bg-transparent border border-solid border-sky-500 hover:bg-sky-500 hover:text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    v-on:click="toggleModalListaentrada()"
                                  >
                                    Voltar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="showModal"
                            class="opacity-25 fixed inset-0 z-40 bg-black"
                          ></div>
                        </div>
                      </td>
                      <td
                        v-if="listCashflows_b.situacao == 'FINALIZADO'"
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <RouterLinkButton
                          btnText=""
                          color="green"
                          tipo="fas"
                          icone="boxes-packing"
                          url=""
                          @click="updateEsolitacao(listESocitacao.id)"
                        />
                      </td>
                      <th
                        v-if="listCashflows_b.id !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listCashflows_b.id }}
                      </th>
                      <th
                        v-if="listCashflows_b.dt_compra !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ formatDate(listCashflows_b.dt_compra) }}
                      </th>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        v-if="listCashflows_b.valorTcompra !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listCashflows_b.valorTcompra }}
                      </td>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listCashflows_b.situacao }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2">
                  <v-pagination
                    v-model="page"
                    :pages="pageCountCashflows_buy"
                    :range-size="1"
                    active-color="#337aff"
                    @update:modelValue="getEntradaSolicitacaoList"
                  />
                </div>
              </div>
              <div class="bg-green-500 w-full h-1"></div>
              <div class="text-gray-900 text-xl">Fluxo Saída(Venda)</div>
              <div class="block w-full overflow-x-auto">
                <table
                  class="items-center w-full bg-transparent border-collapse"
                >
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        ORDEM
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        DATA VENDA
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        VALOR TOTAL VENDA
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        STATUS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="listCashflows_s in listCashflows_sale"
                      :key="listCashflows_sale.id"
                    >
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <!-- <router-link
                          :to="'solicitacaosaidaEproduto/' + listCashflows_s.id"
                          class="underline text-blue-500 hover:text-blue-600"
                        >
                          <fas :icon="['fas', 'pen']" />
                        </router-link>
                      </td> -->
                        <div class="w-full md:w-auto px-6">
                          <form
                            @submit.prevent="onSubmitVenda"
                            @click="getVendaList(listCashflows_s.dt_venda)"
                            v-on:click="toggleModalListasaida()"
                          >
                            <LoadingButton
                              :isLoading="isLoadingVenda"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              <fas :icon="['fas', 'pen']" />
                            </LoadingButton>
                          </form>
                        </div>
                        <div
                          v-if="showModalListasaida"
                          class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                        >
                          <div class="relative w-auto my-6 mx-auto max-w-3xl">
                            <!--content-->
                            <div
                              class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            >
                              <!--header
                                  class="shadow-md h-16 w-16 m-4 bg-teal-400 inline-flex"-->
                              <div
                                class="flex p-5 border-b border-solid border-slate-200 rounded-t"
                              ></div>
                              <!--body-->
                              <div class="text-gray-600 text-xl">
                                Pedido(s) de venda por dia
                              </div>
                              <div class="bg-green-500 w-full h-1"></div>
                              <table class="table-auto text-xs">
                                <thead>
                                  <tr class="text-gray-600 bg-gray-300">
                                    <th class="border px-4 py-2">Pedido</th>
                                    <th class="border px-4 py-2">Cliente</th>
                                    <th class="border px-4 py-2">Data</th>
                                    <th class="border px-4 py-2">
                                      valor total
                                    </th>
                                    <th class="border px-4 py-2">Status</th>
                                    <th class="border px-4 py-2">Ação</th>
                                    <th class="border px-4 py-2"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-if="listSolicitVendas"
                                    class="text-gray-500 bg-gray-100"
                                    v-for="listSolicitVenda in listSolicitVendas"
                                    :key="listSolicitVenda"
                                  >
                                    <td>{{ listSolicitVenda.id }}</td>
                                    <td>
                                      {{
                                        listSolicitVenda.customer.nomeFantasia
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        formatDate(listSolicitVenda.dt_emissao)
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        formatMoedaBR(
                                          listSolicitVenda.valorTotalPedido
                                        )
                                      }}
                                    </td>
                                    <td>
                                      {{ listSolicitVenda.situacao }}
                                    </td>
                                    <td>
                                      <router-link
                                        :to="
                                          'solicitacaosaidaEproduto/' +
                                          listSolicitVenda.id
                                        "
                                        class="underline text-blue-500 hover:text-blue-600"
                                      >
                                        <fas :icon="['fas', 'pen']" />
                                      </router-link>
                                    </td>
                                    <td
                                      v-if="
                                        listCashflows_s.situacao !==
                                        'FINALIZADO'
                                      "
                                      class="underline text-blue-500 hover:text-blue-600"
                                    >
                                      <RouterLinkButton
                                        btnText=""
                                        color="red"
                                        tipo="fas"
                                        icone="trash"
                                        url=""
                                        @click="
                                          cancelaEpedidovenda(
                                            listSolicitVenda.id
                                          )
                                        "
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="bg-green-500 w-full h-1"></div>
                              <div class="text-gray-600 text-xl">
                                Resumo dos Pedido(s) por dia
                              </div>
                              <div class="grid grid-cols-3 gap-4">
                                <div class="text-gray-600 text-xl">
                                  Total Venda :
                                  {{ formatMoedaBR(this.valorTotalVendas) }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a vísta :
                                  {{ formatMoedaBR(this.valorTotalVista) }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a prazo :
                                  {{ formatMoedaBR(this.valorTotalPrazo) }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a débito :
                                  {{ formatMoedaBR(this.valorTotalDebito) }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a crédito :
                                  {{ formatMoedaBR(this.valorTotalCredito) }}
                                </div>
                              </div>
                              <vue-snotify />
                              <div
                                class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                              >
                                <div
                                  class="h-full flex items-center px-3"
                                ></div>
                                <button
                                  class="text-sky-500 bg-transparent border border-solid border-sky-500 hover:bg-sky-500 hover:text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  v-on:click="toggleModalListasaida()"
                                >
                                  Voltar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="showModal"
                          class="opacity-25 fixed inset-0 z-40 bg-black"
                        ></div>
                      </td>
                      <th
                        v-if="listCashflows_s.id !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listCashflows_s.id }}
                      </th>
                      <td
                        v-if="listCashflows_s.dt_venda !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ formatDate(listCashflows_s.dt_venda) }}
                      </td>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        v-if="listCashflows_s.valorTvenda !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listCashflows_s.valorTvenda }}
                      </td>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listCashflows_s.situacao }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2">
                  <v-pagination
                    v-model="page"
                    :pages="pageCountVendas"
                    :range-size="1"
                    active-color="#337aff"
                    @update:modelValue="getSaidaSolicitacaoList"
                  />
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-gray-900 text-xl">Fluxo entrada x saída</div>
            <div class="block w-full overflow-x-auto">
              <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr class="bg-gray-300">
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      Ação
                    </th>
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      ORDEM
                    </th>
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      DATA COMPRA
                    </th>
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      DATA VENDA
                    </th>
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      VALOR TOTAL COMPRA
                    </th>
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      VALOR TOTAL VENDA
                    </th>
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      SALDO
                    </th>
                    <th
                      class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    >
                      STATUS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="listCashflows_bxs in listCashflows_buyxsale"
                    :key="listCashflows_buyxsale.id"
                  >
                    <th>
                      <div class="w-full md:w-auto px-6">
                        <form
                          @submit.prevent="onSubmitEXS"
                          @click="getFluxoESList(listCashflows_bxs.id)"
                          v-on:click="toggleModalListaexs()"
                        >
                          <LoadingButton
                            :isLoading="isLoadingEXS"
                            class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                          >
                            <fas :icon="['fas', 'pen']" />
                          </LoadingButton>
                        </form>
                      </div>
                      <div
                        v-if="showModalListaexs"
                        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                      >
                        <div class="relative w-auto my-6 mx-auto max-w-3xl">
                          <!--content-->
                          <div
                            class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                          >
                            <!--header
                                  class="shadow-md h-16 w-16 m-4 bg-teal-400 inline-flex"-->
                            <div
                              class="flex p-5 border-b border-solid border-slate-200 rounded-t"
                            ></div>
                            <!--body-->
                            <div class="text-gray-600 text-xl">
                              Resumo dos Pedido(s) de entrada e venda por dia
                            </div>
                            <div class="bg-green-500 w-full h-1"></div>
                            <div
                              class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                            >
                              <div class="h-full flex items-center px-3"></div>
                              <div class="grid grid-cols-1 gap-4">
                                <div class="text-gray-600 text-xl">
                                  Total Compra x Venda :
                                  {{
                                    formatMoedaBR(this.valorTotalfcVendaxCompra)
                                  }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a vísta Compra x Venda :
                                  {{
                                    formatMoedaBR(
                                      this.valorTotalvistafcVendaxCompra
                                    )
                                  }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a prazo Compra x Venda :
                                  {{
                                    formatMoedaBR(
                                      this.valorTotalaprazofcVendaxCompra
                                    )
                                  }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a débito Compra x Venda :
                                  {{
                                    formatMoedaBR(
                                      this.valorTotaldebitofcVendaxCompra
                                    )
                                  }}
                                </div>
                                <div class="text-gray-600 text-xl">
                                  Total a crédito Compra x Venda :
                                  {{
                                    formatMoedaBR(
                                      this.valorTotalcreditofcVendaxCompra
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="bg-green-500 w-full h-1"></div>
                            <button
                              class="text-sky-500 bg-transparent border border-solid border-sky-500 hover:bg-sky-500 hover:text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              v-on:click="toggleModalListaexs()"
                            >
                              Voltar
                            </button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th
                      v-if="listCashflows_bxs.id !== null"
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      {{ listCashflows_bxs.id }}
                    </th>
                    <td
                      v-if="listCashflows_bxs.dt_compra !== null"
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      {{ formatDate(listCashflows_bxs.dt_compra) }}
                    </td>
                    <td
                      v-else
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      .
                    </td>
                    <td
                      v-if="listCashflows_bxs.dt_venda !== null"
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      {{ formatDate(listCashflows_bxs.dt_venda) }}
                    </td>
                    <td
                      v-else
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      .
                    </td>
                    <td
                      v-if="listCashflows_bxs.valorTcompra !== null"
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      {{ listCashflows_bxs.valorTcompra }}
                    </td>
                    <td
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      .
                    </td>
                    <td
                      v-if="listCashflows_bxs.valorTvenda !== null"
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      {{ listCashflows_bxs.valorTvenda }}
                    </td>
                    <td
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      {{ listCashflows_bxs.saldo }}
                    </td>
                    <td
                      class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                    >
                      {{ listCashflows_bxs.situacao }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="flex items-center justify-center p-2">
                <v-pagination
                  v-model="page"
                  :pages="pageCount_saida"
                  :range-size="1"
                  active-color="#337aff"
                  @update:modelValue="getSaidaSolicitacaoList"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import TextInputMoeda from "@/components/global/TextInputMoeda.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import VPagination from "@hennge/vue3-pagination";
import V3Pagination from "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useUserStore } from "@/store/user-store";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    SubmitFormButton,
    VPagination,
    V3Pagination,
    TextInput,
    TextInputDate,
    TextInputMoeda,
    LoadingButton,
  },

  data() {
    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;
    const doc = new jsPDF();

    let hover = ref(false);
    let showModalentrada = ref(false);
    let showModalListaentrada = ref(false);
    let showModalsaida = ref(false);
    let showModalListasaida = ref(false);
    let showModalListaexs = ref(false);
    let showModalplanejar = ref(false);

    let listEntradaSocitacao = ref([]);

    let searchSaida = ref(null);
    let listSaidas = ref(null);
    let pageSaidas = ref(1);
    let pageCountSaidas = ref(null);

    let searchEntrada = ref(null);
    let listEntradas = ref(null);
    let pageEntradas = ref(1);
    let pageCountEntradas = ref(null);

    let searchPlanejar = ref(null);
    let listPlanejars = ref(null);
    let pagePlanejars = ref(1);
    let pageCountPlanejars = ref(null);

    let searchCashflow_buy = ref(null);
    let listCashflows_buy = ref(null);
    let pageCashflows_buy = ref(1);
    let pageCountCashflows_buy = ref(null);

    let searchCashflow_sale = ref(null);
    let listCashflows_sale = ref(null);
    let pageCashflows_sale = ref(1);
    let pageCountCashflows_sale = ref(null);

    let searchCashflow_buyxsale = ref(null);
    let listCashflows_buyxsale = ref(null);
    let pageCashflows_buyxsale = ref(1);
    let pageCountCashflows_buyxsale = ref(null);

    let searchStockCB = ref(null);
    let listStocks = ref(null);
    let pageStocks = ref(1);
    let pageCountStocks = ref(null);

    let listSolicitCompras = ref(null);
    let listSolicitCompra = ref(null);
    let pageCompras = ref(1);
    let pageCountCompras = ref(null);

    let listSolicitItemscompra = ref(null);
    let listSolicitItemscompras = ref(null);
    let pageItemscompra = ref(1);
    let pageCountItemscompra = ref(null);

    let listSolicitVendas = ref(null);
    let listSolicitVenda = ref(null);
    let pageVendas = ref(1);
    let pageCountVendas = ref(null);

    let listSolicitItemsvenda = ref(null);
    let listSolicitItemsvendas = ref(null);
    let pageItemsvenda = ref(1);
    let pageCountItemsvenda = ref(null);

    return {
      date: new Date().getFullYear(),
      administrador: userStore.administrador,
      id_empresa: userStore.id_empresa,
      id_responsavel: userStore.id,
      url_api: url,
      hover: hover,
      showModalentrada: showModalentrada,
      showModalListaentrada: showModalListaentrada,
      searchEntrada: searchEntrada,
      listEntradas: listEntradas,
      pageEntradas: pageEntradas,
      pageCountEntradas: pageCountEntradas,
      showModalsaida: showModalsaida,
      showModalListasaida: showModalListasaida,
      showModalListaexs: showModalListaexs,
      searchSaida: searchSaida,
      listSaidas: listSaidas,
      pageSaidas: pageSaidas,
      pageCountSaidas: pageCountSaidas,
      listEntradaSocitacao: listEntradaSocitacao,
      showModalplanejar: showModalplanejar,
      searchPlanejar: searchPlanejar,
      listPlanejars: listPlanejars,
      pagePlanejars: pagePlanejars,
      pageCountPlanejars: pageCountPlanejars,

      searchCashflow_buy: searchCashflow_buy,
      listCashflows_buy: listCashflows_buy,
      pageCashflows_buy: pageCashflows_buy,
      pageCountCashflows_buy: pageCountCashflows_buy,

      searchCashflow_sale: searchCashflow_sale,
      listCashflows_sale: listCashflows_sale,
      pageCashflows_sale: pageCashflows_sale,
      pageCountCashflows_sale: pageCountCashflows_sale,

      searchCashflow_buyxsale: searchCashflow_buyxsale,
      listCashflows_buyxsale: listCashflows_buyxsale,
      pageCashflows_buyxsale: pageCashflows_buyxsale,
      pageCountCashflows_buyxsale: pageCountCashflows_buyxsale,

      searchStockCB: searchStockCB,
      listStocks: listStocks,
      pageStocks: pageStocks,
      pageCountStocks: pageCountStocks,

      listSolicitCompras: listSolicitCompras,
      listSolicitCompra: listSolicitCompra,
      pageCompras: pageCompras,
      pageCountCompras: pageCountCompras,

      listSolicitItemscompra: listSolicitItemscompra,
      listSolicitItemscompras: listSolicitItemscompras,
      pageItemscompra: pageItemscompra,
      pageCountItemscompra: pageCountItemscompra,

      listSolicitVendas: listSolicitVendas,
      listSolicitVenda: listSolicitVenda,
      pageVendas: pageVendas,
      pageCountVendas: pageCountVendas,

      listSolicitItemsvenda: listSolicitItemsvenda,
      listSolicitItemsvendas: listSolicitItemsvendas,
      pageItemsvenda: pageItemsvenda,
      pageCountItemsvenda: pageCountItemsvenda,

      isLoadingPlanejar: ref(false),
      isLoadingStock: ref(false),
      isLoadingItem: ref(false),
      isLoadingCompra: ref(false),
      isLoadingVenda: ref(false),
      isLoadingEXS: ref(false),
      isLoadingImprimir: ref(false),
      id_solicitacao: ref(null),
      id_solicitacaovenda: ref(null),
      id_fornecedor: ref(22),
      nome_fornecedor: ref("FORNECEDOR"),
      id_cliente: ref(22),
      nome_cliente: ref("CONSUMIDOR"),
      id_fluxocaixa: ref(null),
      dt_compra: ref(null),
      dt_compracupom: this.formatDate(new Date()),
      dt_vendacupom: this.formatDate(new Date()),
      formapgto: ref("1"),
      id_produto: ref(null),
      nome_produto: ref(null),
      codigo_barra: ref(null),
      unidade: ref(null),
      vlrunitario: ref(0),
      quantidade: ref("1"),
      vlrtotal_item: ref(0),
      valorTotalPedido: ref(0),
      vlrTotalavista: ref(0),
      vlrTotalaprazo: ref(0),
      vlrTotaldebito: ref(0),
      vlrTotalcredito: ref(0),
      cxqtd: ref(0),
      cxqtdun: ref(0),
      valorTotalaprazofcVendaxCompra: ref(0),
      valorTotalcreditofcVendaxCompra: ref(0),
      valorTotaldebitofcVendaxCompra: ref(0),
      valorTotalfcVendaxCompra: ref(0),
      valorTotalvistafcVendaxCompra: ref(0),
    };
  },
  methods: {
    getCashflowListbuy(searchCashflow) {
      this.pageCountCashflows_buy = null;
      this.listCashflows_buy = null;
      axios
        .get(
          this.url_api +
            "/api/cashflowsearchbuy/" +
            searchCashflow +
            "?page=" +
            this.page_Cashflow_buy
        )
        .then((response) => {
          //console.log(response.data);
          this.pageCountCashflows_buy = response.data.page_count_cashflow_buy;
          this.listCashflows_buy = response.data.paginate_cashflow_buy.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getCashflowListsale(searchCashflow) {
      this.pageCountCashflows_sale = null;
      this.listCashflows_sale = null;
      axios
        .get(
          this.url_api +
            "/api/cashflowsearchsale/" +
            searchCashflow +
            "?page=" +
            this.page_Cashflow_sale
        )
        .then((response) => {
          //console.log(response.data);
          this.pageCountCashflows_sale = response.data.page_count_cashflow_sale;
          this.listCashflows_sale = response.data.paginate_cashflow_sale.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getCashflowListbxs(searchCashflow) {
      this.pageCountCashflows_buyxsale = null;
      this.listCashflows_buyxsale = null;
      axios
        .get(
          this.url_api +
            "/api/cashflowsearchbuyxsale/" +
            searchCashflow +
            "?page=" +
            this.page_Cashflow_buyxsale
        )
        .then((response) => {
          //console.log(response.data);
          this.pageCountCashflows_buyxsale =
            response.data.page_count_cashflow_bxs;
          this.listCashflows_buyxsale =
            response.data.paginate_cashflow_bxs.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getIdplanejarList() {
      axios
        .get(
          this.url_api +
            "/api/planningsearch/" +
            this.searchPlanejar +
            "?page=" +
            this.pagePlanejars
        )
        .then((response) => {
          //console.log(response.data);
          this.pageCountPlanejars = response.data.page_count_planning;
          this.listPlanejars = response.data.paginate_planning.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockListProdCB() {
      axios
        .get(
          this.url_api +
            "/api/stocksearchCB/" +
            this.id_cliente +
            "/" +
            this.searchStockCB +
            "?page=" +
            this.pageStocks
        )
        .then((response) => {
          //console.log(response.data);
          this.pageCountStocks = response.data.page_count;
          this.listStocks = response.data.paginate.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSolicitItemListcompra() {
      axios
        .get(
          this.url_api +
            "/api/salesoitementrylist/" +
            this.id_solicitacao +
            "?page=" +
            this.pageItems
        )
        .then((response) => {
          // Handle the successful response
          //console.log(response.data);
          this.pageCountItemscompra = response.data.page_countItem;
          this.listSolicitItemscompra = response.data.paginateItem.data;
          this.valorTotalPedido = response.data.valorTotalPedido;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSolicitItemListvenda() {
      axios
        .get(
          this.url_api +
            "/api/salesoitemlist/" +
            this.id_solicitacaovenda +
            "?page=" +
            this.pageItemsvenda
        )
        .then((response) => {
          // Handle the successful response
          //console.log(response.data);
          this.pageCountItemsvenda = response.data.page_countItem;
          this.listSolicitItemsvendas = response.data.paginateItem.data;
          this.valorTotalPedido = response.data.valorTotalPedido;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getCompraList(eventCompra) {
      //console.log("Dentro getCompraList : " + eventCompra);
      axios
        .get(
          this.url_api +
            "/api/salesorderentrylistdata/" +
            eventCompra +
            "?page=" +
            this.pageCompras
        )
        .then((response) => {
          // Handle the successful response
          //console.log(response.data);
          this.pageCountCompras = response.data.page_countCompra;
          this.listSolicitCompras = response.data.paginateCompra.data;
          this.valorTotalCompras = response.data.valorTotalCompra;
          this.valorTotalVista = response.data.valorTotalvista;
          this.valorTotalPrazo = response.data.valorTotalaprazo;
          this.valorTotalDebito = response.data.valorTotaldebito;
          this.valorTotalCredito = response.data.valorTotalcredito;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getVendaList(eventVenda) {
      axios
        .get(
          this.url_api +
            "/api/salesordersalelistdata/" +
            eventVenda +
            "?page=" +
            this.pageVendas
        )
        .then((response) => {
          // Handle the successful response
          //console.log(response.data);
          this.pageCountVendas = response.data.page_countVenda;
          this.listSolicitVendas = response.data.paginateVenda.data;
          this.valorTotalVendas = response.data.valorTotalVenda;
          this.valorTotalVista = response.data.valorTotalvista;
          this.valorTotalPrazo = response.data.valorTotalaprazo;
          this.valorTotalDebito = response.data.valorTotaldebito;
          this.valorTotalCredito = response.data.valorTotalcredito;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getFluxoESList(eventId) {
      axios
        .get(
          this.url_api +
            "/api/salesordersalelistexs/" +
            eventId +
            "?page=" +
            this.pageVendas
        )
        .then((response) => {
          // Handle the successful response
          console.log(response.data);
          this.valorTotalaprazofcVendaxCompra =
            response.data.valorTotalaprazofcVendaxCompra;
          this.valorTotalcreditofcVendaxCompra =
            response.data.valorTotalcreditofcVendaxCompra;
          this.valorTotaldebitofcVendaxCompra =
            response.data.valorTotaldebitofcVendaxCompra;
          this.valorTotalfcVendaxCompra =
            response.data.valorTotalfcVendaxCompra;
          this.valorTotalvistafcVendaxCompra =
            response.data.valorTotalvistafcVendaxCompra;
          zerarfcVendaxCompra();
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    deleteItemSolitacao(event) {
      //console.log(event);
      axios
        .delete(this.url_api + "/api/salesoitementry/" + event)
        .then(() => {
          this.$snotify.success("Deletado com sucesso !");
          this.getSolicitItemList();
        })
        .catch((error) => {
          this.errors = error.data.error;
          console.error("Error deleteItemSolitacao:", this.error);
          this.$snotify.error("Verificar Item !");
          return error;
        });
    },
    toggleModalentrada() {
      this.showModalentrada = !this.showModalentrada;
    },
    toggleModalListaentrada() {
      this.showModalListaentrada = !this.showModalListaentrada;
    },
    toggleModalsaida() {
      this.showModalsaida = !this.showModalsaida;
    },
    toggleModalListasaida() {
      this.showModalListasaida = !this.showModalListasaida;
    },
    toggleModalListaexs() {
      this.showModalListaexs = !this.showModalListaexs;
    },
    toggleModalplanejar() {
      this.showModalplanejar = !this.showModalplanejar;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
    },
    formatMoedaBR(moedaString) {
      const atual = moedaString;
      const valorFormatado = Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
      }).format(atual);

      return valorFormatado;
    },
    formatMoedaUS(moedaStringUS) {
      const atual = moedaStringUS;
      const valorFormatado = Intl.NumberFormat("en-US", {
        style: "decimal",
      }).format(atual);
      return valorFormatado;
    },
    selectPlanejar(event) {
      //console.log(event);
      const data = new Date(event.dt_mes);
      const dia = String(data.getDate()).padStart(2, "0");
      const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
      const ano = data.getFullYear();
      const dataFormatada = `${dia}/${mes}/${ano}`;
      this.dt_mes = mes + "/" + ano;
      this.dt_mes = `${mes}/${ano}`;
      this.aberto = event.aberto;
      this.$snotify.info("Escolheu : " + this.dt_mes);
      this.dt_mesPesquisa = `${ano}-${mes}`;
      this.getCashflowListbuy(this.dt_mesPesquisa);
      this.getCashflowListsale(this.dt_mesPesquisa);
      this.getCashflowListbxs(this.dt_mesPesquisa);
    },
    selectProduct(event) {
      this.id_produto = event.id;
      this.nome_produto = event.nome_produto;
      this.codigo_barra = event.codigo_barra;
      this.unidade = event.unidade;
      this.vlrunitario = event.preco_venda;
      this.$snotify.warning("Escolheu a : " + this.nome_produto);
    },
    listaEfluxocaixa(event) {
      //console.log(event);

      this.$snotify.warning("Em desenvolvimento !");
      // id_solicitacao = event.id;
      // axios
      //   .delete(this.url_api + "/api/salesorderentry/" + event)
      //   .then((resDelete) => {
      //     //console.log(resDelete.data);
      //     this.getEntradaSolicitacaoList();
      //     this.$snotify.warning("deletou : " + resDelete.data);
      //   })
      //   .catch((error) => {
      //     this.getEntradaSolicitacaoList();
      //     this.$snotify.warning("Solicitação já deletado : ");
      //     console.error("Error listaEfluxocaixa():", error);
      //   });
    },
    cancelaEpedidocompra(event) {
      //this.$snotify.warning(event);

      axios
        .delete(this.url_api + "/api/salesorderentry/" + event)
        .then((resDelete) => {
          //console.log(resDelete.data);
          // this.getEntradaSolicitacaoList();
          this.$snotify.warning("Cancelado : " + resDelete.data);
        })
        .catch((error) => {
          // this.getEntradaSolicitacaoList();
          this.$snotify.warning("Solicitação já cancelado : ");
          console.error("Error cancelaEpedidocompra():", error);
        });
    },
    cancelaEpedidovenda(event) {
      //this.$snotify.warning(event);

      axios
        .delete(this.url_api + "/api/salesorder/" + event)
        .then((resDelete) => {
          //console.log(resDelete.data);
          // this.getEntradaSolicitacaoList();
          this.$snotify.warning("Cancelado : " + resDelete.data);
        })
        .catch((error) => {
          // this.getEntradaSolicitacaoList();
          this.$snotify.warning("Solicitação já cancelado : ");
          console.error("Error cancelaEpedidocompra():", error);
        });
    },
    updateEsolitacao(event) {
      this.$snotify.info("Mensagem : " + event.id);
      try {
        id_solicitacao = event.id;
        let resEstoque = axios.get(
          this.url_api + "/api/salesorderElessstock/" + id_solicitacao
        );
        this.$snotify.info("Mensagem : " + resEstoque.data);
      } catch (err) {
        console.log(err);
      }
    },
    imprimirEsolitacao() {
      //console.log("dentro imprimirEsolitacao");
      this.$snotify.info("Em desenvolvimento ! ");
      // axios
      //   .get(this.url_api + "/api/salesorderentryprint/" + "teste de impressao")
      //   .then((resImprimir) => {
      //     console.log(resImprimir.data);
      //     this.$snotify.warning("Aguarde a impressão : ");
      //   })
      //   .catch((error) => {
      //     this.$snotify.warning("Verificar Solicitação : ");
      //     console.error("Error imprimirEsolitacao():", error);
      //   });
    },
    gerarPDF() {
      this.$snotify.info("Em desenvolvimento ! ");
      //      try {
      //        doc.text("TESTE ", 105, 10, null, null, "center");

      //        autoTable(doc, { html: "#my-table" });

      //        doc.save("CUPOM.pdf");
      //      } catch (err) {
      //        console.log(err);
      //      }
    },
    multiplicarprod() {
      //alert(this.vlrunitario);
      this.vlrtotal_item = this.quantidade * parseFloat(this.vlrunitario);
    },
    multiplicacxunidade() {
      //alert(this.vlrunitario);
      this.quantidade = this.cxqtd * this.cxqtdun;
    },
    calculaformapgto() {
      this.vlrTotalavista =
        this.valorTotalPedido -
        (parseFloat(this.vlrTotalaprazo) +
          parseFloat(this.vlrTotaldebito) +
          parseFloat(this.vlrTotalcredito));
    },
    registerSolicitacaocompra() {
      if (this.id_solicitacao == null) {
        if (this.codigo_barra == null) {
          this.$snotify.warning("Enter request product(Incluir produto)");
        } else {
          //console.log("Dentro Solicitacao this.id_solicitacao == null");
          this.error = [];
          let data = new FormData();
          data.append("id_responsavel", this.id_responsavel || "");
          data.append("id_fornecedor", this.id_fornecedor || "");
          data.append("dt_compracupom", this.dt_compracupom || "");
          data.append("formapgto", this.formapgto || "");
          axios
            .post(this.url_api + "/api/salesorderentry", {
              id_responsavel: this.id_responsavel,
              id_fornecedor: this.id_fornecedor,
              dt_compracupom: this.dt_compracupom,
              formapgto: this.formapgto,
            })
            .then((registercpe) => {
              //console.log(registercpe.data);
              this.id_solicitacao = registercpe.data.salesorderentry.id;
              this.$snotify.success(
                "Product successfully included in the request(Produto incluido com sucesso na solicitação)"
              );
              this.registerItemSolicitacaocompra();
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              this.$snotify.error("Verifique, falta informação !");
            });
        }
      } else {
        if (this.codigo_barra == null) {
          this.$snotify.warning("Enter request product(Incluir produto)");
        } else {
          if (this.id_solicitacao !== null) {
            //console.log("Dentro Solicitacao this.id_solicitacao == null");
            this.registerItemSolicitacaocompra();
          }
        }
      }
    },
    registerSolicitacaovenda() {
      if (this.id_solicitacaovenda == null) {
        if (this.codigo_barra == null) {
          this.$snotify.warning("Enter request product(Incluir produto)");
        } else {
          //console.log("Dentro Solicitacao this.id_solicitacao == null");
          this.error = [];
          let data = new FormData();
          data.append("id_responsavel", this.id_responsavel || "");
          data.append("id_cliente", this.id_cliente || "");
          data.append("dt_vendacupom", this.dt_vendacupom || "");
          axios
            .post(this.url_api + "/api/salesorder", {
              id_responsavel: this.id_responsavel,
              id_cliente: this.id_cliente,
              dt_vendacupom: this.dt_vendacupom,
            })
            .then((registercpe) => {
              //console.log(registercpe.data);
              this.id_solicitacaovenda = registercpe.data.salesorder.id;
              this.$snotify.success(
                "Product successfully included in the request(Produto incluido com sucesso na solicitação)"
              );
              this.registerItemSolicitacaovenda();
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              this.$snotify.error("Verifique, falta informação !");
            });
        }
      } else {
        if (this.codigo_barra == null) {
          this.$snotify.warning("Enter request product(Incluir produto)");
        } else {
          if (this.id_solicitacaovenda !== null) {
            //console.log("Dentro Solicitacao this.id_solicitacao == null");
            this.registerItemSolicitacaovenda();
          }
        }
      }
    },
    registerItemSolicitacaocompra() {
      let dataItem = new FormData();
      dataItem.append("id_solicitacao", this.id_solicitacao || "");
      dataItem.append("id_produto", this.id_produto || "");
      dataItem.append("codigo_barra", this.codigo_barra || "");
      dataItem.append("nome_produto", this.nome_produto || "");
      dataItem.append("unidade", this.unidade || "");
      dataItem.append("vlrunitario", this.vlrunitario || "");
      dataItem.append("quantidade", this.quantidade || "");
      dataItem.append("vlrtotal_item", this.vlrtotal_item || "");
      axios
        .post(this.url_api + "/api/salesoitementry", {
          id_pedido_item: this.id_solicitacao,
          id_prod_item: this.id_produto,
          codigo_barra: this.codigo_barra,
          nome_produto: this.nome_produto,
          unidade: this.unidade,
          valor_unitario: this.vlrunitario,
          quantidade: this.quantidade,
          valor_totalProduto: this.vlrtotal_item,
        })
        .then((registercpe) => {
          //console.log(registercpe.data);
          this.id_solicitacao = registercpe.data.salesorderentry.id;
          this.$snotify.success(
            "Product successfully included in the request(Produto incluido com sucesso na solicitação)"
          );
          this.getSolicitItemList();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique, falta informação !");
        });
    },
    registerItemSolicitacaovenda() {
      let dataItem = new FormData();
      dataItem.append("id_solicitacaovenda", this.id_solicitacaovenda || "");
      dataItem.append("id_produto", this.id_produto || "");
      dataItem.append("codigo_barra", this.codigo_barra || "");
      dataItem.append("nome_produto", this.nome_produto || "");
      dataItem.append("unidade", this.unidade || "");
      dataItem.append("vlrunitario", this.vlrunitario || "");
      dataItem.append("quantidade", this.quantidade || "");
      dataItem.append("vlrtotal_item", this.vlrtotal_item || "");
      axios
        .post(this.url_api + "/api/salesoitem", {
          id_pedido_item: this.id_solicitacaovenda,
          id_prod_item: this.id_produto,
          codigo_barra: this.codigo_barra,
          nome_produto: this.nome_produto,
          unidade: this.unidade,
          valor_unitario: this.vlrunitario,
          quantidade: this.quantidade,
          valor_totalProduto: this.vlrtotal_item,
        })
        .then((registercpe) => {
          //console.log(registercpe.data);
          this.id_solicitacaovenda = registercpe.data.salesorder.id;
          this.$snotify.success(
            "Product successfully included in the request(Produto incluido com sucesso na solicitação)"
          );
          this.getSolicitItemListvenda();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique, falta informação !");
        });
    },
    finalizarCupomcompra() {
      this.error = [];
      let data = new FormData();
      data.append("id_responsavel", this.id_responsavel || "");
      data.append("id_fornecedor", this.id_fornecedor || "");
      data.append("dt_compracupom", this.dt_compracupom || "");
      data.append("valorTotalPedido", this.valorTotalPedido || "");
      data.append("vlrTotalavista", this.vlrTotalavista || "");
      data.append("vlrTotalaprazo", this.vlrTotalaprazo || "");
      data.append("vlrTotaldebito", this.vlrTotaldebito || "");
      data.append("vlrTotalcredito", this.vlrTotalcredito || "");
      axios
        .put(this.url_api + "/api/salesorderentry/" + this.id_solicitacao, {
          id_responsavel: this.id_responsavel,
          id_fornecedor: this.id_fornecedor,
          dt_compracupom: this.dt_compracupom,
          valorTotalPedido: this.valorTotalPedido,
          vlrTotalavista: this.vlrTotalavista,
          vlrTotalaprazo: this.vlrTotalaprazo,
          vlrTotaldebito: this.vlrTotaldebito,
          vlrTotalcredito: this.vlrTotalcredito,
        })
        .then(() => {
          // console.log(teste)
          this.zerarCupomcompra();
          this.$snotify.success("Finalizado com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          // this.errors = error.data.error;
          console.error("Error salesorderentry: finalizarCupom()", this.error);
          this.$snotify.error(
            "Verificar Planejamento, Empresa, Fornecedor ou as datas !"
          );
          return error;
        });
    },
    finalizarCupomvenda() {
      this.error = [];
      let data = new FormData();
      data.append("id_responsavel", this.id_responsavel || "");
      data.append("id_cliente", this.id_cliente || "");
      data.append("dt_vendacupom", this.dt_vendacupom || "");
      data.append("valorTotalPedido", this.valorTotalPedido || "");
      data.append("vlrTotalavista", this.vlrTotalavista || "");
      data.append("vlrTotalaprazo", this.vlrTotalaprazo || "");
      data.append("vlrTotaldebito", this.vlrTotaldebito || "");
      data.append("vlrTotalcredito", this.vlrTotalcredito || "");
      axios
        .put(this.url_api + "/api/salesorder/" + this.id_solicitacaovenda, {
          id_responsavel: this.id_responsavel,
          id_cliente: this.id_cliente,
          dt_vendacupom: this.dt_vendacupom,
          valorTotalPedido: this.valorTotalPedido,
          vlrTotalavista: this.vlrTotalavista,
          vlrTotalaprazo: this.vlrTotalaprazo,
          vlrTotaldebito: this.vlrTotaldebito,
          vlrTotalcredito: this.vlrTotalcredito,
        })
        .then(() => {
          // console.log(teste)
          this.zerarCupomvenda();
          this.$snotify.success("Finalizado com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          // this.errors = error.data.error;
          console.error("Error salesorder: finalizarCupom()", this.error);
          this.$snotify.error(
            "Verificar Planejamento, Empresa, cliente ou as datas !"
          );
          return error;
        });
    },
    zerarCupomcompra() {
      this.searchStockCB = ref(null);
      this.listStocks = ref(null);
      this.pageStocks = ref(1);
      this.pageCountStocks = ref(null);

      this.listSolicitItemscompra = ref(null);
      this.listSolicitItemscompras = ref(null);
      this.pageItems = ref(1);
      this.pageCountItems = ref(null);

      this.id_solicitacao = ref(null);
      this.id_fornecedor = ref(22);
      this.id_fluxocaixa = ref(null);
      this.nome_fornecedor = ref("FORNECEDOR");
      this.dt_compra = ref(null);
      this.dt_compracupom = this.formatDate(new Date());
      this.formapgto = ref("1");
      this.id_produto = ref(null);
      this.nome_produto = ref(null);
      this.codigo_barra = ref(null);
      this.unidade = ref(null);
      this.vlrunitario = ref(0);
      this.quantidade = ref("1");
      this.vlrtotal_item = ref(0);
      this.valorTotalPedido = ref(0);
      this.cxqtd = ref(0);
      this.cxqtdun = ref(0);
      this.vlrTotalavista = ref(0);
      this.vlrTotalaprazo = ref(0);
      this.vlrTotaldebito = ref(0);
      this.vlrTotalcredito = ref(0);
    },
    zerarCupomvenda() {
      this.searchStockCB = ref(null);
      this.listStocks = ref(null);
      this.pageStocks = ref(1);
      this.pageCountStocks = ref(null);

      this.listSolicitItemsvenda = ref(null);
      this.listSolicitItemsvendas = ref(null);
      this.pageItemsvenda = ref(1);
      this.pageCountItemsvenda = ref(null);

      this.id_solicitacaovenda = ref(null);
      this.id_cliente = ref(22);
      this.id_fluxocaixa = ref(null);
      this.nome_cliente = ref("CONSUMIDOR");
      this.dt_compra = ref(null);
      this.dt_vendacupom = this.formatDate(new Date());
      this.formapgto = ref("1");
      this.id_produto = ref(null);
      this.nome_produto = ref(null);
      this.codigo_barra = ref(null);
      this.unidade = ref(null);
      this.vlrunitario = ref(0);
      this.quantidade = ref("1");
      this.cxqtd = ref(0);
      this.cxqtdun = ref(0);
      this.vlrtotal_item = ref(0);
      this.valorTotalPedido = ref(0);
      this.vlrTotalavista = ref(0);
      this.vlrTotalaprazo = ref(0);
      this.vlrTotaldebito = ref(0);
      this.vlrTotalcredito = ref(0);
    },
    zerarfcVendaxCompra() {
      this.valorTotalaprazofcVendaxCompra = ref(0);
      this.valorTotalcreditofcVendaxCompra = ref(0);
      this.valorTotaldebitofcVendaxCompra = ref(0);
      this.valorTotalfcVendaxCompra = ref(0);
      this.valorTotalvistafcVendaxCompra = ref(0);
    },
    onSubmitPlanejar() {
      this.isLoadingPlanejar = true;
      setTimeout(() => (this.isLoadingPlanejar = false), 1000);
    },
    onSubmitStock() {
      this.isLoadingStock = true;
      setTimeout(() => (this.isLoadingStock = false), 1000);
    },
    onSubmitItem() {
      this.isLoadingItem = true;
      setTimeout(() => (this.isLoadingItem = false), 1000);
    },
    onSubmitCompra() {
      this.isLoadingCompra = true;
      setTimeout(() => (this.isLoadingCompra = false), 1000);
    },
    onSubmitVenda() {
      this.isLoadingVenda = true;
      setTimeout(() => (this.isLoadingVenda = false), 1000);
    },
    onSubmitEXS() {
      this.isLoadingEXS = true;
      setTimeout(() => (this.isLoadingEXS = false), 1000);
    },
    onSubmitImprimir() {
      this.isLoadingImprimir = true;
      setTimeout(() => (this.isLoadingImprimir = false), 1000);
    },
    onPageChange() {
      this.getSolicitItemList();
      this.zerarCupomvenda();
      this.zerarCupomcompra();
    },
    mounted() {
      this.getSolicitItemList();
      this.zerarCupomvenda();
      this.zerarCupomcompra();
    },
  },
};
</script>
